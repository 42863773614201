import * as React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import gs from "../components/styling/GlobalStyles";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import MusicCard from "../components/MusicCard";
import Title from "../components/styling/Title";
import TagSection from "../components/styling/Tags";
import { FaTheaterMasks, FaHourglassHalf, FaShapes } from "react-icons/fa";
import { MdPiano } from "react-icons/md";

// ! Edit page title here so it updates the title as well as SEO !
const pageTitle = "Musical Works";

// #region styling
const MusicList = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin: 0 0 8px 0;
  @media all and (min-width: ${gs.size.screen.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media all and (min-width: ${gs.size.screen.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const SectionScroll = styled.section`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`;
const DivTagGrid = styled.div`
  display: grid;
  gap: 8px;
  width: 100%;
  margin: 0 0 8px 0;
  grid-template-columns: repeat(1, 1fr);
  @media all and (min-width: ${gs.size.screen.md}) {
    grid-template-columns: repeat(2, 2fr);
  }
  @media all and (min-width: ${gs.size.screen.lg}) {
    grid-template-columns: repeat(2, 2fr);
  }
`;
const DivCollapsable = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-flow: column wrap;
  align-items: end;
  background-color: ${gs.color.blackGreen};
  padding: 8px;
  border-radius: 8px;
  margin: 0 0 16px 0;
`;
const SectionTags = styled.section`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
`;
const BtnReset = styled.button`
  font-size: ${gs.size.font.lg};
  border-radius: 6px;
`;
const P = styled.p`
  font-size: ${gs.size.font.xl};
  font-weight: bold;
`;
// const BtnToggle = styled.button`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   line-height: 1.2;
//   gap: 4;
//   padding: 0;
//   color: ${gs.color.graey};
//   text-decoration: underline;
//   background-color: transparent;
//   &:hover,
//   &:focus-visible {
//     background-color: transparent;
//     color: ${gs.color.white};
//   }
// `
// #endregion

function getTagState(tag, category, filterState) {
  const { typeF, moodF, instrumentationF, tempoF } = filterState;
  switch (category) {
    case "type": {
      return typeF.includes(tag);
    }
    case "mood": {
      return moodF.includes(tag);
    }
    case "instrumentation": {
      return instrumentationF.includes(tag);
    }
    case "tempo": {
      return tempoF.includes(tag);
    }
    default:
      return false;
  }
}

/**
 * @param {allFile: {
 *  nodes: Array
 *  types: Array
 *  moods: Array
 *  instrumentations: Array
 *  tempos: Array
 * }} data the info from the GraphQL pageQuery
 * @returns Music page!
 */
const MusicPage = ({ data }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    unfilteredEntries: data.allFile.nodes,
    entries: data.allFile.nodes,
    typeF: [],
    moodF: [],
    instrumentationF: [],
    tempoF: [],
  });

  React.useEffect(() => {
    dispatch({ type: "update", newEntries: data.allFile.nodes });
  }, [data.allFile.nodes]);

  return (
    <Layout>
      <Title>{pageTitle}</Title>
      <SectionTags>
        <DivCollapsable>
          <DivTagGrid>
            <TagSection
              tagsOfCategory={data.allFile.types}
              category="type"
              filterDispatch={dispatch}
              filterState={state}
              tagStateCallback={getTagState}
            >
              <FaShapes aria-hidden="true" />
              Type
            </TagSection>
            <TagSection
              tagsOfCategory={data.allFile.tempos}
              category="tempo"
              filterDispatch={dispatch}
              filterState={state}
              tagStateCallback={getTagState}
            >
              <FaHourglassHalf aria-hidden="true" /> Tempo
            </TagSection>
            <TagSection
              tagsOfCategory={data.allFile.moods}
              category="mood"
              filterDispatch={dispatch}
              filterState={state}
              tagStateCallback={getTagState}
            >
              <FaTheaterMasks aria-hidden="true" />
              Mood
            </TagSection>
            <TagSection
              tagsOfCategory={data.allFile.instrumentations}
              category="instrumentation"
              filterDispatch={dispatch}
              filterState={state}
              tagStateCallback={getTagState}
            >
              <MdPiano aria-hidden="true" />
              Instrumentation
            </TagSection>
          </DivTagGrid>
          <BtnReset
            type="button"
            onClick={() => {
              dispatch({
                type: "reset",
                newEntries: data.allFile.nodes,
              });
            }}
          >
            Reset Filters
          </BtnReset>
        </DivCollapsable>
      </SectionTags>
      <SectionScroll id="Music-List">
        {state.entries && state.entries.length > 0 ? (
          <MusicList>
            {state.entries.map((node) =>
              node.childrenMarkdownRemark.map((entry) => (
                <MusicCard entry={entry} key={entry.id} />
              ))
            )}
          </MusicList>
        ) : (
          <P>
            No entries found with the current filters.{" "}
            <BtnReset
              as="button"
              type="button"
              onClick={() => {
                dispatch({
                  type: "reset",
                  newEntries: data.allFile.nodes,
                });
              }}
            >
              Reset Filters
            </BtnReset>
          </P>
        )}
      </SectionScroll>
    </Layout>
  );
};

export default MusicPage;

// #region Extra Functions
function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

/**
 * @param {initialFilters} state
 * @param {string} action.type entry_update, add_filter, remove_filter
 * @param {string} action.tagCategory type, mood, instrumentation, tempo
 * @param {string} action.$field the adjustment
 */
function reducer(state, action) {
  const newState = structuredClone(state);
  switch (action.type) {
    case "reset":
      return {
        unfilteredEntries: action.newEntries,
        entries: action.newEntries,
        typeF: [],
        moodF: [],
        instrumentationF: [],
        tempoF: [],
      };
    case "update":
      newState.unfilteredEntries = action.newEntries;
      break;
    case "add":
      switch (action.tagCategory) {
        case "type":
          newState.typeF = [action.tag].concat(newState.typeF);
          break;
        case "mood":
          newState.moodF = [action.tag].concat(newState.moodF);
          break;
        case "instrumentation":
          newState.instrumentationF = [action.tag].concat(
            newState.instrumentationF
          );
          break;
        case "tempo":
          newState.tempoF = [action.tag].concat(newState.tempoF);
          break;
        default:
          break;
      }
      break;
    case "remove":
      switch (action.tagCategory) {
        case "type":
          newState.typeF = removeItemOnce(newState.typeF, action.tag);
          break;
        case "mood":
          newState.moodF = removeItemOnce(newState.moodF, action.tag);
          break;
        case "instrumentation":
          newState.instrumentationF = removeItemOnce(
            newState.instrumentationF,
            action.tag
          );
          break;
        case "tempo":
          newState.tempoF = removeItemOnce(newState.tempoF, action.tag);
          break;
        default:
          break;
      }
      break;
    default:
      return state;
  }

  // make sure entries exist
  newState.entries = newState.unfilteredEntries;
  // Apply Filters:
  if (newState.typeF?.length > 0) {
    newState.entries = newState.entries.filter(({ childrenMarkdownRemark }) =>
      childrenMarkdownRemark.every((entry) =>
        newState.typeF.some((f) => entry.frontmatter.type.includes(f))
      )
    );
  }
  if (newState.moodF?.length > 0) {
    newState.entries = newState.entries.filter(({ childrenMarkdownRemark }) =>
      childrenMarkdownRemark.every((entry) => {
        return newState.moodF.some((f) => entry.frontmatter.mood.includes(f));
      })
    );
  }
  if (newState.instrumentationF?.length > 0) {
    newState.entries = newState.entries.filter(({ childrenMarkdownRemark }) =>
      childrenMarkdownRemark.every((entry) => {
        return newState.instrumentationF.some((f) =>
          entry.frontmatter.instrumentation.includes(f)
        );
      })
    );
  }
  if (newState.tempoF?.length > 0) {
    newState.entries = newState.entries.filter(({ childrenMarkdownRemark }) =>
      childrenMarkdownRemark.every((entry) => {
        return newState.tempoF.some((f) => entry.frontmatter.tempo.includes(f));
      })
    );
  }

  return newState;
}
// #endregion

export const Head = () => <Seo title={pageTitle} />;

export const pageQuery = graphql`
  {
    allFile(
      filter: { sourceInstanceName: { eq: "music" } }
      sort: { fields: childrenMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      nodes {
        childrenMarkdownRemark {
          id
          html
          frontmatter {
            title
            date(formatString: "YYYY")
            dateOverride
            audioSrc
            project
            type
            mood
            instrumentation
            tempo
          }
        }
      }
      types: distinct(field: childMarkdownRemark___frontmatter___type)
      moods: distinct(field: childMarkdownRemark___frontmatter___mood)
      instrumentations: distinct(
        field: childMarkdownRemark___frontmatter___instrumentation
      )
      tempos: distinct(field: childMarkdownRemark___frontmatter___tempo)
    }
  }
`;
