import * as React from "react"
import styled from "@emotion/styled"
import gs from "../components/styling/GlobalStyles"
import MusicPlayer from "./styling/MusicPlayer"
import { FaTheaterMasks, FaHourglassHalf, FaShapes } from "react-icons/fa"
import { MdPiano } from "react-icons/md"

const background = gs.color.white
const text = gs.color.blackGreen
const midText = gs.color.secondary
const lightText = gs.color.primary

//#region styling
const LiMusicCard = styled.li`
  padding: 0;
  border-radius: 8px;
  background-color: ${background};
  height: fit-content;
`
const DivCardBody = styled.div`
  position: relative;
  padding: 0 12px 8px 12px;
  flex-grow: 1;
  background-color: ${background};
  border-radius: 0 0 8px 8px;
  display: flex;
  gap: 4px;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
`
const DivCardHeader = styled.div`
  padding: 12px 12px 4px 12px;
  position: relative;
  border-radius: 8px 8px 0 0;
  background-color: ${background};
`
// !Important! Hi. If you ever run into issues with your markdown not looking good,
// edit fix it the way I fixed the <p> and <a> tags here with an embedded selector
const SpanMDDescription = styled.span`
  flex-grow: 1;
  p {
    margin: 0;
    color: ${text};
  }
  a {
    ${gs.linkAlt};
  }
`
const H3 = styled.h3`
  ${gs.font.default};
  font-size: 1.5rem;
  margin: 0;
  padding: 0 3rem 0 0;
  color: ${gs.color.green};
  word-wrap: break-word;
  overflow-wrap: anywhere;
`
const PProject = styled.p`
  margin: 0;
  line-height: 1;
  display: flex;
  font-weight: bold;
  color: ${midText};
  font-size: ${gs.size.font.sm};
  font-style: italic;
`
const PDate = styled.p`
  margin: 0;
  padding: 0 8px;
  position: absolute;
  top: 4px;
  right: 0;
  font-weight: bold;
  font-size: ${gs.size.font.sm};
  color: ${lightText};
`
const SpanWithIcon = styled.span`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 8px;
  color: ${midText};
  padding: 1px 0 0 0;
  svg {
    margin: 2px 0 0 0;
    min-width: 1rem;
  }
`
const PTags = styled.p`
  display: flex;
  line-height: 1.2;
  font-weight: bold;
  margin: 0;
  color: ${midText};
  padding: 1px 0 0 0;
`
const DivTags = styled.div`
  width: 100%;
  display: grid;
  gap: 0 16px;
  grid-template-columns: repeat(2, 2fr);
`
//#endregion

/**
 * @param {boolean} toggleAll used to toggle the tags visibility
 * @param {{
 * html: html
 * frontmatter: {
 *  title: string
 *  date: Date
 *  audioSrc?: string
 *  project?: string
 *  type: string
 *  mood?: string[]
 *  instrumentation?: string[]
 *  tempo?: string
 * }
 * }} entry the required data to fill out the card details from a graphQL pageQuery
 * @returns An individual Music Entry Card
 */
function MusicCard({ entry, toggleAll, ...props }) {
  /**
   * If you're looking at this and wondering wtf is happening, JS is kinda like a spoiled child no one says no to —
   * there's many random ways to do the same thing that 90% of the time just make it more confusing than convenient.
   * Anyway, this is one of those alternatives thats slightly more convenient sometimes:
   * const {title} = entry.frontmatter
   * IS THE SAME AS
   * const title = entry.frontmatter.title
   */
  const {
    title,
    type,
    date,
    dateOverride,
    audioSrc,
    project,
    mood,
    instrumentation,
    tempo,
  } = entry.frontmatter
  let moodString = ""
  let insString = ""
  if (mood) {
    moodString = mood.map((m, index) => {
      return index < mood.length - 1 ? m + ", " : m
    })
  }
  if (instrumentation) {
    insString = instrumentation.map((ins, index) => {
      return index < instrumentation.length - 1 ? ins + ", " : ins
    })
  }
  return (
    <LiMusicCard {...props}>
      <DivCardHeader>
        <PDate>{dateOverride ? dateOverride : date}</PDate>
        <H3>{title}</H3>
        {project && <PProject>{project}</PProject>}
      </DivCardHeader>
      {audioSrc && <MusicPlayer src={audioSrc} />}
      <DivCardBody>
        <SpanMDDescription dangerouslySetInnerHTML={{ __html: entry.html }} />
        <DivTags>
          <SpanWithIcon aria-details="The type of media this music was composed for">
            <FaShapes aria-hidden="true" />
            <PTags>{type}</PTags>
          </SpanWithIcon>
          {tempo && (
            <SpanWithIcon aria-details="The general pace of this music">
              <FaHourglassHalf aria-hidden="true" />
              <PTags>{tempo}</PTags>
            </SpanWithIcon>
          )}
          {moodString && (
            <SpanWithIcon aria-details="Some of the moods explored in this music">
              <FaTheaterMasks aria-hidden="true" />
              <PTags>{moodString}</PTags>
            </SpanWithIcon>
          )}
          {insString && (
            <SpanWithIcon aria-details="The instrumentation of this music">
              <MdPiano aria-hidden="true" />
              <PTags>{insString}</PTags>
            </SpanWithIcon>
          )}
        </DivTags>
      </DivCardBody>
    </LiMusicCard>
  )
}

export default MusicCard
