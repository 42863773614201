import * as React from "react"
import styled from "@emotion/styled"
import gs from "./GlobalStyles"
import AudioPlayer from "react-h5-audio-player"
import {
  FaPlayCircle,
  FaBackward,
  FaForward,
  FaPauseCircle,
} from "react-icons/fa"

const btn = gs.color.green
const btnHover = gs.color.darkGreen
const filled = gs.color.green
const text = gs.color.darkGreen
// const transluscent = gs.color.graeyShadow
const greenOpa = gs.color.greenShadow

//#region styling
const Player = styled(AudioPlayer)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  padding: 8px 12px;
  //border-radius: 0 0 8px 8px;
  background-color: ${gs.color.white};

  &:focus:not(:focus-visible) {
    outline: 0;
  }
  svg {
    vertical-align: initial;
  }

  .rhap_header {
    margin-bottom: 10px;
  }

  .rhap_footer {
    margin-top: 5px;
  }

  .rhap_main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
  }

  .rhap_horizontal {
    flex-direction: row;

    .rhap_controls-section {
      margin-left: 8px;
    }
  }

  .rhap_horizontal-reverse {
    flex-direction: row-reverse;

    .rhap_controls-section {
      margin-right: 8px;
    }
  }

  .rhap_stacked-reverse {
    flex-direction: column-reverse;

    .rhap_controls-section {
      margin-bottom: 8px;
    }
  }

  .rhap_progress-section {
    display: flex;
    flex: 3 1 auto;
    align-items: start;  
    .rhap_rewind-button{
      display: none;
    }
    .rhap_forward-button{
      display: none;
    }
  }

  .rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;    
    cursor: pointer;
    user-select: none;
    margin: 4px 12px 4px 16px;
    -webkit-user-select: none;
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .rhap_time {
    ${gs.font.default};
    font-weight: 700;
    color: ${text};
    font-size: ${gs.size.font.sm};
    user-select: none;
    -webkit-user-select: none;
    margin: 6px 0 0 0;
  }

  .rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: ${greenOpa};
    border-radius: 2px;
  }

  .rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: ${filled};
    border-radius: 2px;
  }

  .rhap_progress-bar-show-download {
    background-color: ${gs.color.graeyShadow};
  }

  .rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: ${filled};
    border-radius: 2px;
  }

  .rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    border-radius: 50px;
    box-shadow: ${gs.color.greenShadow};
    background: ${btn};
    transition: background-color 100ms ease-in;
    &:hover,
    &:focus-visible, &:active {      
      background-color: ${btnHover};
    }
  }

  .rhap_controls-section {
    display: flex;
    flex: 1 1 auto;
    justify-content: start;
    align-items: center;
    margin: -32px 0 0 72px;
    position: relative;    
    .rhap_play-pause-button{
      display: none;
    }
  }

  .rhap_additional-controls {
    display: none;
    flex: 1 0 auto;
    align-items: center;
  }

  .rhap_repeat-button {
    font-size: 26px;
    width: 26px;
    height: 26px;
    margin-right: 6px;
    color: ${btn};
    transition: color 100ms ease-in;
    &:hover,
    &:focus-visible, &:active {      
      color: ${btnHover};
    }
  }

  .rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 16px;
  }

  .rhap_main-controls-button {
    margin: 0 1px;
    color: ${btn};
    font-size: 30px;
    width: 30px;
    height: 30px;
    &:hover,
    &:focus-visible, &:active {      
      color: ${btnHover};
    }
  }

  .rhap_play-pause-button {
    font-size: 64px;
    width: 64px;
    height: 64px;
  }

  .rhap_volume-controls {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
    margin: 0 4px 0 0;
  }

  .rhap_volume-button {
    flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: ${btn};
    margin-right: 6px;
    display: block;
    &:hover,
    &:focus-visible, &:active {      
      color: ${btnHover};
    }
  }

  .rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    user-select: none;
    -webkit-user-select: none;
  }

  .rhap_volume-bar-area {
    display: flex;
    align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

  .rhap_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 4px;
    background: ${greenOpa};
    border-radius: 2px;
  }

  .rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    border-radius: 50px;
    box-shadow:${gs.color.greenShadow}
    cursor: pointer;
    z-index: 3;
    transition: background-color 100ms ease-in;
    background-color: ${btn};
    &:hover,
    &:focus-visible, &:active {      
      background-color: ${btnHover};
    }
  }

  .rhap_volume-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: ${filled};
    border-radius: 2px;
  }

  /* Utils */
  .rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      //opacity: 0.9;
      transition-duration: 0.2s;
    }

    &:active {
      //opacity: 0.95;
    }

    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }

`
const SpanSlash = styled.span`
  color: ${text};
  font-size: ${gs.size.font.sm};
  font-weight: bold;
  margin: 6px 2px;
`
//#endregion

/**
 *
 * @param {String} src Source of the audio file
 * @returns the audio player
 */
function MusicPlayer({ src }) {
  return (
    <Player
      src={src}
      showFilledVolume={true}
      showDownloadProgress={false}
      customAdditionalControls={[]}
      customProgressBarSection={[
        "MAIN_CONTROLS",
        "PROGRESS_BAR",
        "CURRENT_TIME",
        <SpanSlash>/</SpanSlash>,
        "DURATION",
      ]}
      customIcons={{
        play: <FaPlayCircle title="Play Arrow in Circle" aria-hidden="true" />,
        pause: (
          <FaPauseCircle
            title="Double Vertical Pause Lines in Circle"
            aria-hidden="true"
          />
        ),
        rewind: (
          <FaBackward title="Backwards Double Arrows" aria-hidden="true" />
        ),
        forward: (
          <FaForward title="Forwards Double Arrows" aria-hidden="true" />
        ),
      }}
    />
  )
}

export default MusicPlayer
